<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-subheader class="title">
            Messages
            <div class="flex-grow-1"></div>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-subheader>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-data-table
              :headers="headers"
              :items="messages"
              :items-per-page="15"
              class="elevation-0"
              :search="search"
            >
              <!-- <template #item="{headers, item}">
                <tr :class="[item.status === 'unread' ? '' : 'grey lighten-5']"
                >
                  <td v-for="header in headers" :key="header.value">{{item[header.value]}}</td>
                </tr>
              </template>-->
              <template #item.status="{ item }">
                <v-chip
                  @click="readMessage(item)"
                  color="success"
                  small
                  v-if="item.status === 'unread'"
                >New</v-chip>
                <v-chip @click="readMessage(item)" small v-else>Read</v-chip>
              </template>
              <template v-slot:item.action="{ item }">
                <!-- <v-icon small @click="deleteMessage(item.id)">reading</v-icon> -->
                <v-icon small color="red" @click="deleteMessage(item.id)">delete</v-icon>
              </template>
            </v-data-table>
          </v-card>
          <v-dialog v-model="dialog" max-width="500px" transition="dialog-transition">
            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo">mdi-home</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{read.name}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo">mdi-phone</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{read.phone}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo">mdi-email</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{read.email}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo">mdi-message-text</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{read.subject}}</v-list-item-title>
                    <v-list-item-subtitle class="pt-5">{{read.message}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      read: {},
      search: "",
      messages: [],
      headers: [
        {
          text: "Subject",
          align: "left",
          sortable: false,
          value: "subject"
        },
        { text: "Messages", value: "message", sortable: false },
        { text: "Name", value: "name", sortable: false },
        { text: "Status", value: "status", color: "primary" },
        { text: "Action", value: "action", sortable: false }
      ]
    };
  },
  computed: {
    ...mapGetters(["getMessages"])
  },
  methods: {
    ...mapActions(["bind", "delete", "update"]),
    fetchMessages() {
      if (!this.getMessages.length) {
        this.bind({
          collection: "messages",
          data: "messages"
        }).then(() => {
          this.messages = this.getMessages;
        });
      } else {
        this.messages = this.getMessages;
      }
    },
    readMessage(item) {
      this.read = item;
      this.dialog = true;
      this.update({
        collection: "messages",
        doc: item.id,
        data: {
          status: "read"
        }
      });
    },
    deleteMessage(id) {
      this.delete({
        collection: "messages",
        doc: id
      });
    }
  },
  created() {
    this.fetchMessages();
  }
};
</script>
